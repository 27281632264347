<div class="container-tudo">
    <div class="fundo-imagem"></div>
    <div class="center-content">
        <form class="example-form" [formGroup]="redefinirSenha">
            <div class="login-header">
                <img class="logo" src="/assets/images/logo-karid.png" alt="">
                <h1>Redefina sua Senha</h1>
                @if (authenticationError()) {
                    <div class="alert alert-danger" data-cy="loginError">
                        <strong>Falha ao logar!</strong> Por favor, verifique suas credenciais e tente novamente.
                    </div>
                }
            </div>
            <div class="login-card">
                <div class="login-content">
                    <div class="field">

                        <label for="passwordTrue">Nova Senha</label>
                        <p-password id="passwordTrue" class="password" formControlName="senha" placeholder="Nova Senha"
                                    [feedback]="false" [toggleMask]="true"/>
                        @if (redefinirSenha.get('senha')?.touched && redefinirSenha.get('senha')?.hasError('required')) {
                            <small>
                                O campo Senha não pode estar vazio!
                            </small>
                        }

                    </div>

                    <div class="field">

                        <label for="passwordF2A">Repita Sua Senha</label>
                        <p-password id="passwordF2A" class="password" placeholder="Repita a senha"
                                    [feedback]="false" [toggleMask]="true" formControlName="senhaConfirm"/>
                        @if (redefinirSenha.get('senha')?.touched && redefinirSenha.invalid) {
                            <small>
                              As senhas tem que ser idênticas
                            </small>
                        }

                    </div>
                    <div class="button-area">

                        <p-button [outlined]="false" class="custom-cancelar-button" label="Cancelar"
                                  (click)="cancelar()"/>
                        <p-button [outlined]="false" [disabled]="redefinirSenha.invalid" class="custom-entrar-button"
                                  label="Alterar Senha"
                                  (click)="trocarSenha()"/>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>