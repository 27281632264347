<div class="total">
<div class="card">
    <div class="title">
        <img class="logo" src="/assets/images/logo-karid.png" alt="">
        <h1>Solicitar Alteração de Senha</h1> </div>
        <div class="div">
        <p-divider type="solid"></p-divider>
    </div>


    <div class="campo-email">
        <form [formGroup]="sendEmail">
            <app-text-input-form [limpar]="false" customFormControlName="email" label="Email" placeholder="Email do Usuário" id="email">
                @if(sendEmail.get('email')?.touched && sendEmail.invalid){
                    <small>Digite o email</small>
                }
            </app-text-input-form>
        </form>
    </div>
    <div class="button-area">

        <p-button [outlined]="false" class="custom-cancelar-button" label="Cancelar"
                  (click)="cancelar()"/>
        <p-button [outlined]="false" [disabled]="sendEmail.invalid" class="custom-entrar-button"
                  label="Alterar Senha"
                  (click)="solicitar()"/>
    </div>
</div></div>