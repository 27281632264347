import { Routes } from '@angular/router';
import {BaseLayoutComponent} from "./layout/base-layout/base-layout.component";
import {RedefinirSenhaComponent} from "./public/redefinir-senha/redefinir-senha.component";
import {SolicitarSenhaComponent} from "./public/solicitar-senha/solicitar-senha.component";
import {LoginComponent} from "./public/login/login.component";

export const APP_ROUTES: Routes = [
    {
        path: '',
        component: BaseLayoutComponent,
        loadChildren: () => import('./pages/pages.routes').then((r) => r.ROUTES)
    },
    {
        path: 'entities',
        component: BaseLayoutComponent,
        loadChildren: () => import('./entities/entities.routes').then((r) => r.ROUTES)

    },
    {
        path: 'redefinir-senha/:token',
        component: RedefinirSenhaComponent,
        pathMatch: 'full',
    },
    {
        path: 'solicitar-senha',
        component: SolicitarSenhaComponent,
        pathMatch: 'full',
    },
    {
        path: 'login',
        pathMatch: 'full',
        component: LoginComponent
    }
];
