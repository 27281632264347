import {ApplicationConfig, inject, provideZoneChangeDetection} from '@angular/core';
import {NavigationError, provideRouter, Router, RouterFeatures, withNavigationErrorHandler} from '@angular/router';

import {APP_ROUTES} from './app.routes';
import {provideClientHydration} from '@angular/platform-browser';
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {provideHttpClient, withFetch, withInterceptorsFromDi} from "@angular/common/http";
import {httpInterceptorProviders} from './core/interceptor';
import {ConfirmationService} from "primeng/api";

const routerFeatures: RouterFeatures[] = [
    withNavigationErrorHandler((e: NavigationError) => {
        const router = inject(Router);
        if (e.error.status === 401) {
            router.navigate(['/login']);
        } else if (e.error.status === 500) {
            console.log('mandou pra /error')
            router.navigate(['/error']);
        }
    }),
];

export const appConfig: ApplicationConfig = {
    providers: [
        provideZoneChangeDetection({eventCoalescing: true}),
        provideRouter(APP_ROUTES, ...routerFeatures),
        provideClientHydration(),
        ConfirmationService,
        provideAnimationsAsync('noop'),
        provideHttpClient(withFetch(), withInterceptorsFromDi()),
        httpInterceptorProviders,
    ]
};
