<div class="total">
    <div class="formulario">
        <div class="form-area">
            <div class="texto">
                <div>
                    <h1 class="cabecalho">Bem vindo (a)</h1>
                </div>
                <div>
                    <h3 class="descricao">Descubra uma maneira de fazer consulta veicular com transparência, praticidade
                        e segurança.</h3>
                </div>
            </div>
            <form [formGroup]="login" class="login" (ngSubmit)="onSubmit($event)">
                <div class="campos">
                    <app-text-input-form
                            [limpar]="false"
                            labelStyle="font-family:Poppins, sans-serif; font-weight: 450"
                            [placeholder]="'Seu usuário'" [id]="'username'"
                            [customFormControlName]="'username'" [label]="'Usuário'"></app-text-input-form>
                    @if (login.get('username')?.touched && login.get('username')?.hasError('required')) {
                        <small>
                            O campo usuário não pode estar vazio!
                        </small>
                    }


                    <span class="field">
                    <label for="password">Senha</label>
                    <p-password id="password" class="password" formControlName="password" placeholder="Sua senha"
                                [feedback]="false" [toggleMask]="true"/>
                        @if (login.get('password')?.touched && login.get('password')?.hasError('required')) {
                            <small>
                            O campo senha não pode estar vazio!
                        </small>
                        }
                </span>
                    <div class="esqueceu-senha">
                        <a class="solictar-senha" (click)="trocarSenha()">Esqueceu sua senha?</a></div>
                    @if (hasAuthenticationError) {
                        <div class="alert alert-danger" data-cy="loginError">
                            <strong>Falha ao logar!</strong> Por favor, verifique suas credenciais e tente novamente.
                        </div>
                    }
                </div>
                <input type="hidden" name="g-recaptcha-resp" id="g-recaptcha-resp" formControlName="recaptcha">

                <div class="botao">
                    <p-button [outlined]="false" [disabled]="login.invalid" class="custom-entrar-button"
                              type="submit" label="Entrar"
                    />
                </div>
                <div class="foot--mobile">
                    <img src="/assets/images/poweredbyPlaceBlack.png" alt="">
                </div>
            </form>
        </div>
    </div>
    <div class="imagens">
        <div class="top">
            <img src="/assets/images/favicon.ico">
            <span class="place">Place</span>
            <span class="auto">Auto</span>
        </div>
        <div class="foot--img">
            <img src="/assets/images/powerdByPlace.png" alt="">
        </div>
    </div>
</div>