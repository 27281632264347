import {Component, ElementRef, inject, signal, viewChild} from '@angular/core';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {TextInputFormComponent} from "../../shared/components/text-input-form/text-input-form.component";
import {PasswordModule} from "primeng/password";
import {Button} from "primeng/button";
import {Router} from "@angular/router";
import {LoginService} from "./login.service";

@Component({
  selector: 'app-new-login',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TextInputFormComponent,
    PasswordModule,
    Button,
    FormsModule,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {
  sitekey: string = '6LdL-NkqAAAAABkVXf4yxpkjTOfivCm6w6Bm7UdS';
  private readonly router = inject(Router);
  username = viewChild.required<ElementRef>('username');

  authenticationError = signal(false);

  login = new FormGroup({
    username: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    password: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    recaptcha: new FormControl(''),
  });

  trocarSenha(){
    this.router.navigate(['/solicitar-senha'])
  }

  private readonly loginService = inject(LoginService);

  fazerLogin(): void {
    this.loginService.login(this.login.getRawValue()).subscribe({
      next: () => {
        this.authenticationError.set(false);
        if (!this.router.getCurrentNavigation()) {
          this.router.navigate(['']);
        }
      },
      error: () => this.authenticationError.set(true),
    });
  }

  get hasAuthenticationError() {
    return this.authenticationError();
  }

  async onSubmit(event: Event) {
    event.preventDefault();

    try {
      const token = await (window as any).grecaptcha.enterprise.execute(this.sitekey, { action: 'LOGIN' });
      this.login.patchValue({ recaptcha: token });

      this.fazerLogin();
    } catch (error) {
      console.error('Erro ao gerar token:', error);
    }
  }


}
