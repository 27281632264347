import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";


@Injectable({
    providedIn: "root",
})
export class LogService {
    disableConsoleInProduction(): void {
        if (environment.production) {
            console.warn("🚨 Console output is disabled on production!");
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            console.log = function (): void {};
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            console.debug = function (): void {};
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            console.warn = function (): void {};
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            console.info = function (): void {};
        }
    }
}
