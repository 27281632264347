import {Component, inject} from '@angular/core';
import {DividerModule} from "primeng/divider";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {TextInputFormComponent} from "../../shared/components/text-input-form/text-input-form.component";
import {Button} from "primeng/button";
import {LoginService} from "../login/login.service";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-solicitar-senha',
  standalone: true,
  imports: [
    DividerModule,
    ReactiveFormsModule,
    TextInputFormComponent,
    Button
  ],
  templateUrl: './solicitar-senha.component.html',
  styleUrl: './solicitar-senha.component.scss'
})
export class SolicitarSenhaComponent {
  sendEmail = new FormGroup({
    email: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
  })
  private readonly loginService = inject(LoginService);
  private readonly router = inject(Router);
  private snackBar: MatSnackBar = inject(MatSnackBar);

  cancelar(){
    this.router.navigate(['/login'])
  }

  solicitar(){
    const body = {
      email: this.sendEmail.get('email')?.value
    };
      this.loginService.solicitarSenha(body).subscribe({
        next: () => {
          this.router.navigate(['/login']);
          this.snackBar.open('Sua solicitação será enviada ao seu email', 'Fechar', {
            duration: 3000,
            panelClass: ['custom-snackbar'],
          });
        },
        error: (err) => {
          console.error('Erro ao redefinir a senha:', err);
        }
      })
  }
}
