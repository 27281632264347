import {Component, ElementRef, inject, OnInit, signal, viewChild} from '@angular/core';
import {Button} from "primeng/button";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule, ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {PasswordModule} from "primeng/password";
import {TextInputFormComponent} from "../../shared/components/text-input-form/text-input-form.component";
import {LoginService} from "../login/login.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {group} from "@angular/animations";

@Component({
  selector: 'app-redefinir-senha',
  standalone: true,
  imports: [
    Button,
    FormsModule,
    PasswordModule,
    ReactiveFormsModule,
    TextInputFormComponent
  ],
  templateUrl: './redefinir-senha.component.html',
  styleUrl: './redefinir-senha.component.scss'
})
export class RedefinirSenhaComponent implements OnInit{

  authenticationError = signal(false);
  private route: ActivatedRoute = inject(ActivatedRoute);
  private snackBar: MatSnackBar = inject(MatSnackBar)
  redefinirSenha = new FormGroup({
    senha: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
    senhaConfirm: new FormControl('', { nonNullable: true, validators: [Validators.required] })
  }, { validators: this.senhasDevemSerIguais() });

  private readonly loginService = inject(LoginService);
  private readonly router = inject(Router);
  uuid: string| null = null;
  ngOnInit(): void {
   this.uuid = this.route.snapshot.paramMap.get('token');
  }

  trocarSenha(): void {
      const body = {
        senha: this.redefinirSenha.get('senha')?.value
      };
      if(this.uuid){
      this.loginService.trocarSenha( this.uuid , body).subscribe({
        next: () => {
          this.router.navigate(['/login']);
          this.snackBar.open('Senha alterada com sucesso', 'Fechar', {
            duration: 3000,
            panelClass: ['custom-snackbar'],
          });
        },
        error: (err) => {
          console.error('Erro ao redefinir a senha:', err);
          this.authenticationError.set(true);
        }
      })}
     else {
      console.warn('Formulário inválido');
    }
  }

  clearInputUsername() {
    this.redefinirSenha.get('senha')?.setValue('');
  }

  clearInputPassword() {
    this.redefinirSenha.get('senha')?.setValue('');
  }

  cancelar() {
    this.router.navigate(['/login'])
  }

  getDynamicClass = (formControlName: string) => {
    const control = this.redefinirSenha.get(formControlName);
    return {
      'visible-icon': !!control?.value,
      'hidden-icon': !control?.value
    };
  };

  senhasDevemSerIguais(): ValidatorFn {
    return (group: AbstractControl): ValidationErrors | null => {
      const senha = group.get('senha')?.value;
      const senhaConfirm = group.get('senhaConfirm')?.value;
      return senha === senhaConfirm ? null : {senhasDiferentes: true};
    };
  }


}
