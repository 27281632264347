import { inject, Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LoginService } from "../../public/login/login.service";

@Injectable()
export class AuthExpiredInterceptor implements HttpInterceptor {
    private readonly loginService = inject(LoginService);
    private readonly router = inject(Router);

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap({
                error: (err: HttpErrorResponse) => {
                    if (err.status === 401 && err.url && !err.url.includes('api/account')) {
                        this.loginService.logout();
                        this.router.navigate(['/login']);
                    }
                },
            }),
        );
    }
}
