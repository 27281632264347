import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { BaseLayoutComponent } from "./layout/base-layout/base-layout.component";
import { SidenavComponent } from "./layout/sidenav/sidenav.component";
import { LogService } from "./core/security/log.service";

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [RouterOutlet, BaseLayoutComponent, SidenavComponent],
    template: `
     <router-outlet></router-outlet>`,
})
export class AppComponent {

    constructor(private logService: LogService) {
        this.logService.disableConsoleInProduction();
    }
}
